html, body {
    background-color: rgb(20, 20, 20);
}

.songbook {
    position:absolute;
    display: flex;
    min-height:100%;
    min-width: 1100px;
    width:100%;
    color: rgb(210, 210, 210);
    flex-direction: row;
    justify-content: center;
}